// RequireAuth.js

// Check whether a user is authenticated;
// Wraps around routes that we want to 'protect' - ie make available to logged in users only.
// if yes, let them navigate to the page they're seeking
// if no, send them to login page.

import { useLocation, Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

export function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== "authenticated") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

