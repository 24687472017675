// see https://docs.google.com/spreadsheets/d/1sU85m08ydIgc8E1oEpLza8KA3rfJBrFC/edit#gid=1295786873

// Analytics Listed by Category
// lambda name = analytics-api-cac1-[ENV] (ENV = dev or uat)
// DAILY EVENTS:
// TRADES
// "trades_trading_cost": handler_trades_trading_cost,
// "trades_trading_imbalance": handler_trades_trading_imbalance,
// "trades_fragmentation": handler_trades_fragmentation,
// "trades_ohlcv": handler_trades_ohlcv,
// TOP OF BOOK:
// "tob_market_quote_insights": handler_tob_market_quote_insights,
// "tob_venue_quote_insights": handler_tob_venue_quote_insights,
// "tob_broker_quote_insights": handler_tob_broker_quote_insights,
// "tob_broker_venue_quote_insights": handler_tob_broker_venue_quote_insights,
// "tob_quote_activity_consolidated": handler_tob_quote_activity_consolidated,
// "tob_quote_activity_venue": handler_tob_quote_activity_venue,
// LIMIT ORDER BOOK:
// "lob_order_additions": handler_lob_order_additions,
// "lob_order_cancellations": handler_lob_order_cancellations,
// "lob_order_executions": handler_lob_order_executions,
// "lob_order_activity": handler_lob_order_activity,
// "lob_order_lifetimes": handler_lob_order_lifetimes,
// DAILY EVENTS:
// "daily_events_close": handler_daily_events_close,
// "daily_events_open": handler_daily_events_open,
export interface Indicator {
  label: string;
  group: string;
  groupLabel: string;
  id: string;
  parentGroup?: string;
  scale?: number;
}
const analyticsIndicatorList: Indicator[] = [
  /**************************
   * TRADES
   *************************/

  ///////////////////////////
  // Trading Imbalance
  ///////////////////////////

  {
    label: "Trading Imbalance",
    group: "trades_trading_imbalance",
    groupLabel: "Trading Imbalance",
    id: "trading_imbalance",
    parentGroup: "Trades",
  },
  {
    label: "Trading Imbalance Norm.",
    group: "trades_trading_imbalance",
    groupLabel: "Trading Imbalance",
    id: "trading_imbalance_normalized",
    parentGroup: "Trades",
  },
  {
    label: "Trading Imbalance Value",
    group: "trades_trading_imbalance",
    groupLabel: "Trading Imbalance",
    id: "trading_imbalance_value",
    parentGroup: "Trades",
  },
  {
    label: "Trading Value Imb. Norm.",
    group: "trades_trading_imbalance",
    groupLabel: "Trading Imbalance",
    id: "trading_value_imbalance_normalized",
    parentGroup: "Trades",
  },
  {
    label: "Value Buys",
    group: "trades_trading_imbalance",
    groupLabel: "Trading Imbalance",
    id: "value_buys",
    parentGroup: "Trades",
  },
  {
    label: "Value Sells",
    group: "trades_trading_imbalance",
    groupLabel: "Trading Imbalance",
    id: "value_sells",
    parentGroup: "Trades",
  },
  {
    label: "Volume Buys (10k)",
    group: "trades_trading_imbalance",
    groupLabel: "Trading Imbalance",
    id: "volume_buys",
    scale: 1.0 / 1e4,
    parentGroup: "Trades",
  },
  {
    label: "Volume Sells (10k)",
    group: "trades_trading_imbalance",
    groupLabel: "Trading Imbalance",
    id: "volume_sells",
    scale: 1.0 / 1e4,
    parentGroup: "Trades",
  },

  //////////////////////////////////
  // Trading Cost
  //////////////////////////////////

  {
    label: "Effective Spread",
    group: "trades_trading_cost",
    groupLabel: "Trading Cost Analytics",
    id: "effective_spread",
    scale: 1.0,
    parentGroup: "Trades",
  },
  {
    label: "Effective Spread %",
    group: "trades_trading_cost",
    groupLabel: "Trading Cost Analytics",
    id: "pct_effective_spread",
    scale: 100.0,
    parentGroup: "Trades",
  },
  {
    label: "Realized Spread",
    group: "trades_trading_cost",
    groupLabel: "Trading Cost Analytics",
    id: "realized_spread",
    scale: 1.0,
    parentGroup: "Trades",
  },

  {
    label: "Price Impact",
    group: "trades_trading_cost",
    groupLabel: "Trading Cost Analytics",
    id: "price_impact",
    scale: 1.0,
    parentGroup: "Trades",
  },
  {
    label: "Price Impact %",
    group: "trades_trading_cost",
    groupLabel: "Trading Cost Analytics",
    id: "pct_price_impact",
    scale: 100.0,
    parentGroup: "Trades",
  },
  {
    label: "Realized Spread %",
    group: "trades_trading_cost",
    groupLabel: "Trading Cost Analytics",
    id: "pct_realized_spread",
    scale: 100.0,
    parentGroup: "Trades",
  },
  //////////////////////////////
  // Fragmentation
  //////////////////////////////

  {
    label: "Venue trade fragmentation - Volume-weighted",
    group: "trades_fragmentation",
    groupLabel: "Trade Fragmentation",
    id: "venue_trade_fragmentation_volume_weighted",
    scale: 1,
    parentGroup: "Trades",
  },
  {
    label: "Venue trade fragmentation - Value-weighted",
    group: "trades_fragmentation",
    groupLabel: "Trade Fragmentation",
    id: "venue_trade_fragmentation_value_weighted",
    scale: 1,
    parentGroup: "Trades",
  },
  {
    label: "Broker trade fragmentation",
    group: "trades_fragmentation",
    groupLabel: "Trade Fragmentation",
    id: "broker_trade_fragmentation",
    scale: 1,
  },
  {
    label: "Broker trade fragmentation - Volume-weighted",
    group: "trades_fragmentation",
    groupLabel: "Trade Fragmentation",
    id: "broker_trade_fragmentation_volume_weighted",
    scale: 1,
    parentGroup: "Trades",
  },
  {
    label: "Broker trade fragmentation - Value-weighted",
    group: "trades_fragmentation",
    groupLabel: "Trade Fragmentation",
    id: "broker_trade_fragmentation_value_weighted",
    scale: 1,
    parentGroup: "Trades",
  },

  /////////////////////////////
  // OHLCV
  //////////////////////////////
  // {
  //   label: "Open trade price",
  //   group: "trades_ohlcv",
  //   groupLabel: "OHLCV+",
  //   id: "open_trade_price",
  //   scale: 1,
  // },
  // {
  //   label: "Close trade price",
  //   group: "trades_ohlcv",
  //   groupLabel: "OHLCV+",
  //   id: "close_trade_price",
  //   scale: 1,
  // },
  // {
  //   label: "Highest trade price",
  //   group: "trades_ohlcv",
  //   groupLabel: "OHLCV+",
  //   id: "highest_trade_price",
  //   scale: 1,
  // },
  // {
  //   label: "Lowest trade price",
  //   group: "trades_ohlcv",
  //   groupLabel: "OHLCV+",
  //   id: "lowest_trade_price",
  //   scale: 1,
  // },
  // {
  //   label: "Average trade price",
  //   group: "trades_ohlcv",
  //   groupLabel: "OHLCV+",
  //   id: "average_trade_price",
  //   scale: 1,
  // },
  // {
  //   label: "Volume weighted average trade price",
  //   group: "trades_ohlcv",
  //   groupLabel: "OHLCV+",
  //   id: "volume_weighted_average_trade_price",
  //   scale: 1,
  // },
  // {
  //   label: "Average trade volume",
  //   group: "trades_ohlcv",
  //   groupLabel: "OHLCV+",
  //   id: "average_trade_volulme",
  //   scale: 1,
  // },
  // {
  //   label: "Total number of trades",
  //   group: "trades_ohlcv",
  //   groupLabel: "OHLCV+",
  //   id: "total_number_of_trades",
  //   scale: 1,
  // },
  // {
  //   label: "Total volume",
  //   group: "trades_ohlcv",
  //   groupLabel: "OHLCV+",
  //   id: "total_volume",
  //   scale: 1,
  // },
  // {
  //   label: "Total value",
  //   group: "trades_ohlcv",
  //   groupLabel: "OHLCV+",
  //   id: "total_value",
  //   scale: 1,
  // },

  /***************************
   * Top of Book**
   ****************************/

  //////////////////////////////
  // Market Quote Insights
  //////////////////////////////

  {
    label: "Time-weighted average price at NBB",
    group: "tob_market_quote_insights",
    groupLabel: "Market Quotes Insights",
    id: "twa_nbb",
    scale: 1,
  },
  {
    label: "Time-weighted average price at NBO",
    group: "tob_market_quote_insights",
    groupLabel: "Market Quotes Insights",
    id: "twa_nbo",
    scale: 1,
  },
  {
    label: "Time-weighted average volume at NBB",
    group: "tob_market_quote_insights",
    groupLabel: "Market Quotes Insights",
    id: "twa_nbb_size",
    scale: 1,
  },
  {
    label: "Time-weighted average volume at NBO",
    group: "tob_market_quote_insights",
    groupLabel: "Market Quotes Insights",
    id: "twa_nbo_size",
    scale: 1,
  },
  {
    label: "Time-weighted average value at NBB",
    group: "tob_market_quote_insights",
    groupLabel: "Market Quotes Insights",
    id: "twa_nbb_value",
    scale: 1,
  },
  {
    label: "Time-weighted average value at NBO",
    group: "tob_market_quote_insights",
    groupLabel: "Market Quotes Insights",
    id: "twa_nbo_value",
    scale: 1,
  },
  {
    label: "Time-weighted average quoted spread at NBBO",
    group: "tob_market_quote_insights",
    groupLabel: "Market Quotes Insights",
    id: "twa_nbbo_quote_spread",
    scale: 1,
  },
  {
    label: "Time-weighted average relative quoted spread at NBBO",
    group: "tob_market_quote_insights",
    groupLabel: "Market Quotes Insights",
    id: "twa_nbbo_rel_quote_spread",
    scale: 1,
  },
  // {
  //   label: "Percent of time crossed markets",
  //   group: "tob_market_quote_insights",
  //   groupLabel: "Market Quotes Insights",
  //   id: "percent_of_time_crossed_markets",
  //   scale: 1,
  // },
  // {
  //   label: "Percent of time locked markets",
  //   group: "tob_market_quote_insights",
  //   groupLabel: "Market Quotes Insights",
  //   id: "percent_of_time_locked_markets",
  //   scale: 1,
  // },

  //////////////////////////////////
  // Venue Quote Insights
  //////////////////////////////////

  // {
  //   label: "Time-weighted average price at NBB for venue",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Time-weighted average price at NBB for venue",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average price at NBO for venue",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Time-weighted average price at NBO for venue",
  //   scale: 1,
  // },
  // {
  //   label: "Percent of time at NBB for venue",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Percent of time at NBB for venue",
  //   scale: 1,
  // },
  // {
  //   label: "Percent of time at NBO for venue",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Percent of time at NBO for venue",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted percent of volume at NBB for venue",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Time-weighted percent of volume at NBB for venue",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted percent of volume at NBO for venue",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Time-weighted percent of volume at NBO for venue",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted percent of value at NBB for venue",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Time-weighted percent of value at NBB for venue",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted percent of value at NBO for venue",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Time-weighted percent of value at NBO for venue",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average price away from NBB for venue",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Time-weighted average price away from NBB for venue",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average price away from NBO for venue",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Time-weighted average price away from NBO for venue",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average price at venue best bid",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Time-weighted average price at venue best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average price at venue best offer",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Time-weighted average price at venue best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average volume at venue best bid",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Time-weighted average volume at venue best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average volume at venue best offer",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Time-weighted average volume at venue best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average value at venue best bid",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Time-weighted average value at venue best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average value at venue best offer",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Time-weighted average value at venue best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average quoted spread at venue best bid and offer",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Time-weighted average quoted spread at venue best bid and offer",
  //   scale: 1,
  // },
  // {
  //   label:
  //     "Time-weighted average relative quoted spread at venue best bid and offer",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Time-weighted average relative quoted spread at venue best bid and offer",
  //   scale: 1,
  // },
  // {
  //   label: "Percent of time Involved in Crossed Markets for venue",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Percent of time Involved in Crossed Markets for venue",
  //   scale: 1,
  // },
  // {
  //   label: "Percent of time Involved in Locked Markets for venue",
  //   group: "tob_venue_quote_insights",
  //   groupLabel: "Venue Quote Insights",
  //   id: "Percent of time Involved in Locked Markets for venue",
  //   scale: 1,
  // },

  ///////////////////////////
  // Broker Quotes Insights
  ///////////////////////////

  // {
  //   label: "Time-weighted average price at NBB for broker",
  //   group: "tob_broker_quote_insights",
  //   groupLabel: "Broker Quotes Insights",
  //   id: "Time-weighted average price at NBB for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average price at NBO for broker",
  //   group: "tob_broker_quote_insights",
  //   groupLabel: "Broker Quotes Insights",
  //   id: "Time-weighted average price at NBO for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Percent of time at NBB for broker",
  //   group: "tob_broker_quote_insights",
  //   groupLabel: "Broker Quotes Insights",
  //   id: "Percent of time at NBB for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Percent of time at NBO for broker",
  //   group: "tob_broker_quote_insights",
  //   groupLabel: "Broker Quotes Insights",
  //   id: "Percent of time at NBO for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted percent of volume at NBB for broker",
  //   group: "tob_broker_quote_insights",
  //   groupLabel: "Broker Quotes Insights",
  //   id: "Time-weighted percent of volume at NBB for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted percent of volume at NBO for broker",
  //   group: "tob_broker_quote_insights",
  //   groupLabel: "Broker Quotes Insights",
  //   id: "Time-weighted percent of volume at NBO for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted percent of value at NBB for broker",
  //   group: "tob_broker_quote_insights",
  //   groupLabel: "Broker Quotes Insights",
  //   id: "Time-weighted percent of value at NBB for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted percent of value at NBO for broker",
  //   group: "tob_broker_quote_insights",
  //   groupLabel: "Broker Quotes Insights",
  //   id: "Time-weighted percent of value at NBO for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average price away from NBB for broker",
  //   group: "tob_broker_quote_insights",
  //   groupLabel: "Broker Quotes Insights",
  //   id: "Time-weighted average price away from NBB for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average price away from NBO for broker",
  //   group: "tob_broker_quote_insights",
  //   groupLabel: "Broker Quotes Insights",
  //   id: "Time-weighted average price away from NBO for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Percent of time Involved in Crossed Markets for broker",
  //   group: "tob_broker_quote_insights",
  //   groupLabel: "Broker Quotes Insights",
  //   id: "Percent of time Involved in Crossed Markets for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Percent of time Involved in Locked Markets for broker",
  //   group: "tob_broker_quote_insights",
  //   groupLabel: "Broker Quotes Insights",
  //   id: "Percent of time Involved in Locked Markets for broker",
  //   scale: 1,
  // },

  //////////////////////////////////
  // Broker-Venue Quote Insights
  //////////////////////////////////

  // {
  //   label: "Average price at NBB for broker and Venue",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Average price at NBB for broker and Venue",
  //   scale: 1,
  // },
  // {
  //   label: "Average price at NBO for broker and Venue",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Average price at NBO for broker and Venue",
  //   scale: 1,
  // },
  // {
  //   label: "Percent of time at NBB for broker and Venue",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Percent of time at NBB for broker and Venue",
  //   scale: 1,
  // },
  // {
  //   label: "Percent of time at NBO for broker and Venue",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Percent of time at NBO for broker and Venue",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted percent of volume at NBB for broker and Venue",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Time-weighted percent of volume at NBB for broker and Venue",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted percent of volume at NBO for broker and Venue",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Time-weighted percent of volume at NBO for broker and Venue",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average price away from NBB for broker and Venue",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Time-weighted average price away from NBB for broker and Venue",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average price away from NBO for broker and Venue",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Time-weighted average price away from NBO for broker and Venue",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average price at venue best bid for broker",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Time-weighted average price at venue best bid for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average price at venue best offer for broker",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Time-weighted average price at venue best offer for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average volume at venue best bid for broker",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Time-weighted average volume at venue best bid for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average volume at venue best offer for broker",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Time-weighted average volume at venue best offer for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average value at venue best bid for broker",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Time-weighted average value at venue best bid for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average value at venue best offer for broker",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Time-weighted average value at venue best offer for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average price away from Venue best bid for broker",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Time-weighted average price away from Venue best bid for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average price away from Venue best offer for broker",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Time-weighted average price away from Venue best offer for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Time-weighted average Quoted Spread at venue best bid for broker",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Time-weighted average Quoted Spread at venue best bid for broker",
  //   scale: 1,
  // },
  // {
  //   label:
  //     "Time-weighted average Relative Quoted Spread at venue best offer for broker",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Time-weighted average Relative Quoted Spread at venue best offer for broker",
  //   scale: 1,
  // },
  // {
  //   label: "Percent of time at venue best bid for broker and Venue",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Percent of time at venue best bid for broker and Venue",
  //   scale: 1,
  // },
  // {
  //   label: "Percent of time at venue best offer for broker and Venue",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Percent of time at venue best offer for broker and Venue",
  //   scale: 1,
  // },
  // {
  //   label:
  //     "Time-weighted percent of volume at venue best bid for broker and Venue",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Time-weighted percent of volume at venue best bid for broker and Venue",
  //   scale: 1,
  // },
  // {
  //   label:
  //     "Time-weighted percent of volume at venue best offer for broker and Venue",
  //   group: "tob_broker_venue_quote_insights",
  //   groupLabel: "Broker-Venue Quotes Insights",
  //   id: "Time-weighted percent of volume at venue best offer for broker and Venue",
  //   scale: 1,
  // },

  ///////////////////////////////////
  // Quote activity - Consolidated
  ////////////////////////////////////

  {
    label: "NBBO midquote return",
    group: "tob_quote_activity_consolidated",
    groupLabel: "Quote activity - Consolidated",
    id: "qac_nbbo_ret",
    scale: 1,
  },
  {
    label: "NBBO midquote volatility",
    group: "tob_quote_activity_consolidated",
    groupLabel: "Quote activity - Consolidated",
    id: "qac_nbbo_vol",
    scale: 1,
  },
  // {
  //   label: "NBBO midquote autocorrelation",
  // group: "tob_quote_activity_consolidated",
  //   groupLabel: "Quote activity - Consolidated",
  //   id: "NBBO_midquote_autocorrelation",
  //   scale: 1,
  // },
  // {
  //   label: "NBBO midquote variance ratio",
  // group: "tob_quote_activity_consolidated",
  //   groupLabel: "Quote activity - Consolidated",
  //   id: "NBBO_midquote_variance_ratio",
  //   scale: 1,
  // },
  {
    label: "NBB return",
    group: "tob_quote_activity_consolidated",
    groupLabel: "Quote activity - Consolidated",
    id: "qac_nbb_ret",
    scale: 1,
  },
  {
    label: "NBB volatility",
    group: "tob_quote_activity_consolidated",
    groupLabel: "Quote activity - Consolidated",
    id: "qac_nbb_vol",
    scale: 1,
  },
  // {
  //   label: "NBB autocorrelation",
  // group: "tob_quote_activity_consolidated",
  //   groupLabel: "Quote activity - Consolidated",
  //   id: "NBB_autocorrelation",
  //   scale: 1,
  // },
  // {
  //   label: "NBB variance ratio",
  // group: "tob_quote_activity_consolidated",
  //   groupLabel: "Quote activity - Consolidated",
  //   id: "NBB_variance_ratio",
  //   scale: 1,
  // },
  {
    label: "NBO return",
    group: "tob_quote_activity_consolidated",
    groupLabel: "Quote activity - Consolidated",
    id: "qac_nbo_ret",
    scale: 1,
  },
  {
    label: "NBO volatility",
    group: "tob_quote_activity_consolidated",
    groupLabel: "Quote activity - Consolidated",
    id: "qac_nbo_vol",
    scale: 1,
  },
  // {
  //   label: "NBO autocorrelation",
  // group: "tob_quote_activity_consolidated",
  //   groupLabel: "Quote activity - Consolidated",
  //   id: "NBO_autocorrelation",
  //   scale: 1,
  // },
  // {
  //   label: "NBO variance ratio",
  // group: "tob_quote_activity_consolidated",
  //   groupLabel: "Quote activity - Consolidated",
  //   id: "NBO_variance_ratio",
  //   scale: 1,
  // },

  ////////////////////////////////
  // Quote activity - Per venue
  ////////////////////////////////

  {
    label: "Midquote return",
    group: "tob_quote_activity_venue",
    groupLabel: "Quote activity - Per venue",
    id: "qav_nbbo_ret",
    scale: 1,
  },
  {
    label: "Midquote volatility",
    group: "tob_quote_activity_venue",
    groupLabel: "Quote activity - Per venue",
    id: "qav_nbbo_vol",
    scale: 1,
  },
  // {
  //   label: "Midquote autocorrelation",
  // group: "tob_quote_activity_venue",
  //   groupLabel: "Quote activity - Per venue",
  //   id: "midquote_autocorrelation",
  //   scale: 1,
  // },
  // {
  //   label: "Midquote variance ratio",
  // group: "tob_quote_activity_venue",
  //   groupLabel: "Quote activity - Per venue",
  //   id: "midquote_variance_ratio",
  //   scale: 1,
  // },
  {
    label: "Best bid return",
    group: "tob_quote_activity_venue",
    groupLabel: "Quote activity - Per venue",
    id: "qav_nbb_ret",
    scale: 1,
  },
  {
    label: "Best bid volatility",
    group: "tob_quote_activity_venue",
    groupLabel: "Quote activity - Per venue",
    id: "qav_nbb_vol",
    scale: 1,
  },
  // {
  //   label: "Best bid autocorrelation",
  // group: "tob_quote_activity_venue",
  //   groupLabel: "Quote activity - Per venue",
  //   id: "best_bid_autocorrelation",
  //   scale: 1,
  // },
  // {
  //   label: "Best bid variance ratio",
  // group: "tob_quote_activity_venue",
  //   groupLabel: "Quote activity - Per venue",
  //   id: "best_bid_variance_ratio",
  //   scale: 1,
  // },
  {
    label: "Best offer return",
    group: "tob_quote_activity_venue",
    groupLabel: "Quote activity - Per venue",
    id: "qav_nbo_ret",
    scale: 1,
  },
  {
    label: "Best offer volatility",
    group: "tob_quote_activity_venue",
    groupLabel: "Quote activity - Per venue",
    id: "qav_nbo_vol",
    scale: 1,
  },
  // {
  //   label: "Best offer autocorrelation",
  // group: "tob_quote_activity_venue",
  //   groupLabel: "Quote activity - Per venue",
  //   id: "best_offer_autocorrelation",
  //   scale: 1,
  // },
  // {
  //   label: "Best offer variance ratio",
  // group: "tob_quote_activity_venue",
  //   groupLabel: "Quote activity - Per venue",
  //   id: "best_offer_variance_ratio",
  //   scale: 1,
  // },

  /************************
   * Limit Order Book
   ************************/

  ///////////////////////////
  // Order additions
  ///////////////////////////

  // {
  //   label: "Number of order additions",
  //   group: "order_additions",
  //   groupLabel: "Order additions",
  //   id: "Number of order additions",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order additions",
  //   group: "order_additions",
  //   groupLabel: "Order additions",
  //   id: "Quantity of order additions",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order additions",
  //   group: "order_additions",
  //   groupLabel: "Order additions",
  //   id: "Value of order additions",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order additions",
  //   group: "order_additions",
  //   groupLabel: "Order additions",
  //   id: "Number of bid order additions",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order additions",
  //   group: "order_additions",
  //   groupLabel: "Order additions",
  //   id: "Quantity of bid order additions",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order additions",
  //   group: "order_additions",
  //   groupLabel: "Order additions",
  //   id: "Value of bid order additions",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order additions",
  //   group: "order_additions",
  //   groupLabel: "Order additions",
  //   id: "Number of offer order additions",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order additions",
  //   group: "order_additions",
  //   groupLabel: "Order additions",
  //   id: "Quantity of offer order additions",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order additions",
  //   group: "order_additions",
  //   groupLabel: "Order additions",
  //   id: "Value of offer order additions",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order additions at BBO",
  //   group: "order_additions",
  //   groupLabel: "Order additions",
  //   id: "Number of order additions at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order additions at BBO",
  //   group: "order_additions",
  //   groupLabel: "Order additions",
  //   id: "Quantity of order additions at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order additions at BBO",
  //   group: "order_additions",
  //   groupLabel: "Order additions",
  //   id: "Value of order additions at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order additions at best bid",
  //   group: "order_additions",
  //   groupLabel: "Order additions",
  //   id: "Number of bid order additions at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order additions at best bid",
  //   group: "order_additions",
  //   groupLabel: "",
  //   id: "Quantity of bid order additions at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order additions at best bid",
  //   group: "order_additions",
  //   groupLabel: "",
  //   id: "Value of bid order additions at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order additions at best offer",
  //   group: "order_additions",
  //   groupLabel: "",
  //   id: "Number of offer order additions at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order additions at best offer",
  //   group: "order_additions",
  //   groupLabel: "",
  //   id: "Quantity of offer order additions at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order additions at best offer",
  //   group: "order_additions",
  //   groupLabel: "",
  //   id: "Value of offer order additions at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order additions away from BBO",
  //   group: "order_additions",
  //   groupLabel: "",
  //   id: "Number of order additions away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order additions away from BBO",
  //   group: "order_additions",
  //   groupLabel: "",
  //   id: "Quantity of order additions away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order additions away from BBO",
  //   group: "order_additions",
  //   groupLabel: "",
  //   id: "Value of order additions away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order additions away from best bid",
  //   group: "order_additions",
  //   groupLabel: "",
  //   id: "Number of bid order additions away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order additions away from best bid",
  //   group: "order_additions",
  //   groupLabel: "",
  //   id: "Quantity of bid order additions away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order additions away from best bid",
  //   group: "order_additions",
  //   groupLabel: "",
  //   id: "Value of bid order additions away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order additions away from best offer",
  //   group: "order_additions",
  //   groupLabel: "",
  //   id: "Number of offer order additions away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order additions away from best offer",
  //   group: "order_additions",
  //   groupLabel: "",
  //   id: "Quantity of offer order additions away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order additions away from best offer",
  //   group: "order_additions",
  //   groupLabel: "",
  //   id: "Value of offer order additions away from best offer",
  //   scale: 1,
  // },

  /////////////////////////////
  // // Order cancelations
  //////////////////////////////

  // {
  //   label: "Number of order cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of order cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of order cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of order cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of bid order cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of bid order cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of bid order cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of offer order cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of offer order cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of offer order cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order cancelations at BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of order cancelations at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order cancelations at BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of order cancelations at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order cancelations at BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of order cancelations at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order cancelations at best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of bid order cancelations at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order cancelations at best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of bid order cancelations at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order cancelations at best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of bid order cancelations at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order cancelations at best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of offer order cancelations at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order cancelations at best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of offer order cancelations at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order cancelations at best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of offer order cancelations at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order cancelations away from BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of order cancelations away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order cancelations away from BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of order cancelations away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order cancelations away from BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of order cancelations away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order cancelations away from best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of bid order cancelations away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order cancelations away from best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of bid order cancelations away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order cancelations away from best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of bid order cancelations away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order cancelations away from best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of offer order cancelations away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order cancelations away from best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of offer order cancelations away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order cancelations away from best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of offer order cancelations away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order full cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of order full cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order full cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of order full cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order full cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of order full cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order full cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of bid order full cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order full cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of bid order full cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order full cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of bid order full cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order full cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of offer order full cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order full cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of offer order full cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order full cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of offer order full cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order full cancelations at BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of order full cancelations at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order full cancelations at BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of order full cancelations at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order full cancelations at BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of order full cancelations at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order full cancelations at best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of bid order full cancelations at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order full cancelations at best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of bid order full cancelations at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order full cancelations at best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of bid order full cancelations at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order full cancelations at best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of offer order full cancelations at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order full cancelations at best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of offer order full cancelations at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order full cancelations at best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of offer order full cancelations at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order full cancelations away from BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of order full cancelations away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order full cancelations away from BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of order full cancelations away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order full cancelations away from BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of order full cancelations away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order full cancelations away from best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of bid order full cancelations away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order full cancelations away from best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of bid order full cancelations away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order full cancelations away from best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of bid order full cancelations away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order full cancelations away from best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of offer order full cancelations away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order full cancelations away from best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of offer order full cancelations away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order full cancelations away from best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of offer order full cancelations away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order partial cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of order partial cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order partial cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of order partial cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order partial cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of order partial cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order partial cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of bid order partial cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order partial cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of bid order partial cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order partial cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of bid order partial cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order partial cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of offer order partial cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order partial cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of offer order partial cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order partial cancelations",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of offer order partial cancelations",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order partial cancelations at BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of order partial cancelations at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order partial cancelations at BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of order partial cancelations at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order partial cancelations at BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of order partial cancelations at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order partial cancelations at best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of bid order partial cancelations at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order partial cancelations at best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of bid order partial cancelations at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order partial cancelations at best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of bid order partial cancelations at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order partial cancelations at best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of offer order partial cancelations at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order partial cancelations at best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of offer order partial cancelations at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order partial cancelations at best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of offer order partial cancelations at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order partial cancelations away from BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of order partial cancelations away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order partial cancelations away from BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of order partial cancelations away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order partial cancelations away from BBO",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of order partial cancelations away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order partial cancelations away from best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of bid order partial cancelations away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order partial cancelations away from best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of bid order partial cancelations away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order partial cancelations away from best bid",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of bid order partial cancelations away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order partial cancelations away from best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Number of offer order partial cancelations away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order partial cancelations away from best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Quantity of offer order partial cancelations away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order partial cancelations away from best offer",
  //   group: "order_cancellations",
  //   groupLabel: "",
  //   id: "Value of offer order partial cancelations away from best offer",
  //   scale: 1,
  // },
  // Order executions
  // {
  //   label: "Number of order executions",
  //   group: "order_executions",
  //   groupLabel: "",
  //   id: "Number of order executions",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of order executions",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of order executions",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of bid order executions",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of bid order executions",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of bid order executions",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of offer order executions",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of offer order executions",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of offer order executions",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order executions at BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of order executions at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order executions at BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of order executions at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order executions at BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of order executions at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order executions at best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of bid order executions at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order executions at best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of bid order executions at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order executions at best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of bid order executions at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order executions at best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of offer order executions at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order executions at best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of offer order executions at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order executions at best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of offer order executions at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order executions away from BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of order executions away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order executions away from BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of order executions away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order executions away from BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of order executions away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order executions away from best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of bid order executions away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order executions away from best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of bid order executions away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order executions away from best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of bid order executions away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order executions away from best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of offer order executions away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order executions away from best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of offer order executions away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order executions away from best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of offer order executions away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order full executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of order full executions",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order full executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of order full executions",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order full executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of order full executions",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order full executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of bid order full executions",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order full executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of bid order full executions",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order full executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of bid order full executions",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order full executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of offer order full executions",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order full executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of offer order full executions",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order full executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of offer order full executions",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order full executions at BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of order full executions at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order full executions at BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of order full executions at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order full executions at BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of order full executions at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order full executions at best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of bid order full executions at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order full executions at best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of bid order full executions at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order full executions at best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of bid order full executions at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order full executions at best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of offer order full executions at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order full executions at best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of offer order full executions at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order full executions at best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of offer order full executions at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order full executions away from BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of order full executions away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order full executions away from BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of order full executions away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order full executions away from BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of order full executions away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order full executions away from best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of bid order full executions away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order full executions away from best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of bid order full executions away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order full executions away from best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of bid order full executions away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order full executions away from best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of offer order full executions away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order full executions away from best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of offer order full executions away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order full executions away from best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of offer order full executions away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order partial executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of order partial executions",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order partial executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of order partial executions",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order partial executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of order partial executions",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order partial executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of bid order partial executions",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order partial executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of bid order partial executions",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order partial executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of bid order partial executions",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order partial executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of offer order partial executions",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order partial executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of offer order partial executions",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order partial executions",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of offer order partial executions",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order partial executions at BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of order partial executions at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order partial executions at BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of order partial executions at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order partial executions at BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of order partial executions at BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order partial executions at best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of bid order partial executions at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order partial executions at best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of bid order partial executions at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order partial executions at best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of bid order partial executions at best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order partial executions at best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of offer order partial executions at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order partial executions at best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of offer order partial executions at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order partial executions at best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of offer order partial executions at best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Number of order partial executions away from BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of order partial executions away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of order partial executions away from BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of order partial executions away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Value of order partial executions away from BBO",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of order partial executions away from BBO",
  //   scale: 1,
  // },
  // {
  //   label: "Number of bid order partial executions away from best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of bid order partial executions away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of bid order partial executions away from best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of bid order partial executions away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Value of bid order partial executions away from best bid",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of bid order partial executions away from best bid",
  //   scale: 1,
  // },
  // {
  //   label: "Number of offer order partial executions away from best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Number of offer order partial executions away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Quantity of offer order partial executions away from best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Quantity of offer order partial executions away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Value of offer order partial executions away from best offer",
  //   group: "",
  //   groupLabel: "",
  //   id: "Value of offer order partial executions away from best offer",
  //   scale: 1,
  // },
  // {
  //   label: "Order flow imbalance",
  //   group: "",
  //   groupLabel: "",
  //   id: "Order flow imbalance",
  //   scale: 1,
  // },

  /////////////////////
  // Order activity
  /////////////////////

  // {
  //   label: "Cancel to trade ratio",
  //   group: "",
  //   groupLabel: "",
  //   id: "Cancel to trade ratio",
  //   scale: 1,
  // },
  // {
  //   label: "Cancel to trade ratio by quantity",
  //   group: "",
  //   groupLabel: "",
  //   id: "Cancel to trade ratio by quantity",
  //   scale: 1,
  // },
  // {
  //   label: "Cancel to trade ratio by value",
  //   group: "",
  //   groupLabel: "",
  //   id: "Cancel to trade ratio by value",
  //   scale: 1,
  // },
  // {
  //   label: "Full cancel to trade ratio",
  //   group: "",
  //   groupLabel: "",
  //   id: "Full cancel to trade ratio",
  //   scale: 1,
  // },
  // {
  //   label: "Full cancel to trade ratio by quantity",
  //   group: "",
  //   groupLabel: "",
  //   id: "Full cancel to trade ratio by quantity",
  //   scale: 1,
  // },
  // {
  //   label: "Full cancel to trade ratio by value",
  //   group: "",
  //   groupLabel: "",
  //   id: "Full cancel to trade ratio by value",
  //   scale: 1,
  // },
  // {
  //   label: "Partial cancel to trade ratio",
  //   group: "",
  //   groupLabel: "",
  //   id: "Partial cancel to trade ratio",
  //   scale: 1,
  // },
  // {
  //   label: "Partial cancel to trade ratio by quantity",
  //   group: "",
  //   groupLabel: "",
  //   id: "Partial cancel to trade ratio by quantity",
  //   scale: 1,
  // },
  // {
  //   label: "Partial cancel to trade ratio by value",
  //   group: "",
  //   groupLabel: "",
  //   id: "Partial cancel to trade ratio by value",
  //   scale: 1,
  // },
  // {
  //   label: "Trade to order ratio",
  //   group: "",
  //   groupLabel: "",
  //   id: "Trade to order ratio",
  //   scale: 1,
  // },
  // {
  //   label: "Trade to order ratio by quantity",
  //   group: "",
  //   groupLabel: "",
  //   id: "Trade to order ratio by quantity",
  //   scale: 1,
  // },
  // {
  //   label: "Trade to order ratio by value",
  //   group: "",
  //   groupLabel: "",
  //   id: "Trade to order ratio by value",
  //   scale: 1,
  // },
  // {
  //   label: "Hidden rate",
  //   group: "",
  //   groupLabel: "",
  //   id: "Hidden rate",
  //   scale: 1,
  // },
  // {
  //   label: "Hidden rate by quantity",
  //   group: "",
  //   groupLabel: "",
  //   id: "Hidden rate by quantity",
  //   scale: 1,
  // },
  // {
  //   label: "Hidden rate by value",
  //   group: "",
  //   groupLabel: "",
  //   id: "Hidden rate by value",
  //   scale: 1,
  // },
  // {
  //   label: "Hidden volume",
  //   group: "",
  //   groupLabel: "",
  //   id: "Hidden volume",
  //   scale: 1,
  // },
  // {
  //   label: "Odd lot rate",
  //   group: "",
  //   groupLabel: "",
  //   id: "Odd lot rate",
  //   scale: 1,
  // },
  // {
  //   label: "Odd lot rate by quantity",
  //   group: "",
  //   groupLabel: "",
  //   id: "Odd lot rate by quantity",
  //   scale: 1,
  // },
  // {
  //   label: "Odd lot rate by value",
  //   group: "",
  //   groupLabel: "",
  //   id: "Odd lot rate by value",
  //   scale: 1,
  // },
  // {
  //   label: "Odd lot volume",
  //   group: "",
  //   groupLabel: "",
  //   id: "Odd lot volume",
  //   scale: 1,
  // },

  ////////////////////////
  // Order lifetimes
  /////////////////////////

  // {
  //   label: "Average time to execution",
  //   group: "",
  //   groupLabel: "",
  //   id: "Average time to execution",
  //   scale: 1,
  // },
  // {
  //   label: "Average time to partial execution",
  //   group: "",
  //   groupLabel: "",
  //   id: "Average time to partial execution",
  //   scale: 1,
  // },
  // {
  //   label: "Average time to full execution",
  //   group: "",
  //   groupLabel: "",
  //   id: "Average time to full execution",
  //   scale: 1,
  // },
  // {
  //   label: "Average time to cancelation",
  //   group: "",
  //   groupLabel: "",
  //   id: "Average time to cancelation",
  //   scale: 1,
  // },
  // {
  //   label: "Average time to partial cancelation",
  //   group: "",
  //   groupLabel: "",
  //   id: "Average time to partial cancelation",
  //   scale: 1,
  // },
  // {
  //   label: "Average time to full cancelation",
  //   group: "",
  //   groupLabel: "",
  //   id: "Average time to full cancelation",
  //   scale: 1,
  // },

  /***********************
   * Daily Events
   **********************/

  ////////////////////////
  // Open
  ////////////////////////

  // {
  //   label: "Opening Price",
  //   group: "dailyEventsOpen",
  //   groupLabel: "Daily Events - Open",
  //   id: "opening_price",
  //   scale: 1,
  // },
  // {
  //   label: "Opening Time",
  //   group: "dailyEventsOpen",
  //   groupLabel: "Daily Events - Open",
  //   id: "opening_time",
  //   scale: 1,
  // },
  // {
  //   label: "Opening Trade Volume",
  //   group: "dailyEventsOpen",
  //   groupLabel: "Daily Events - Open",
  //   id: "opening_trade_volume",
  //   scale: 1,
  // },
  // {
  //   label: "Trade price of the first trade after market open",
  //   group: "dailyEventsOpen",
  //   groupLabel: "Daily Events - Open",
  //   id: "trade_price_of_the_first_trade_ after_market_open",
  //   scale: 1,
  // },
  // {
  //   label: "Trade time of the first trade after market open",
  //   group: "dailyEventsOpen",
  //   groupLabel: "Daily Events - Open",
  //   id: "trade_time_of_the_first_trade_ after_market_open",
  //   scale: 1,
  // },
  // {
  //   label: "First best offer price after market open",
  //   group: "dailyEventsOpen",
  //   groupLabel: "Daily Events - Open",
  //   id: "first_best_offer_price_after_market_open",
  //   scale: 1,
  // },
  // {
  //   label: "First best bid price after market open",
  //   group: "dailyEventsOpen",
  //   groupLabel: "Daily Events - Open",
  //   id: "first_best_bid_price_after_market_open",
  //   scale: 1,
  // },

  //////////////////////////
  // Close
  //////////////////////////

  // {
  //   label: "Closing price",
  //   group: "dailyEventsClose",
  //   groupLabel: "Daily Events - Close",
  //   id: "closing_price",
  //   scale: 1,
  // },
  // {
  //   label: "Closing time",
  //   group: "dailyEventsClose",
  //   groupLabel: "Daily Events - Close",
  //   id: "closing_time",
  //   scale: 1,
  // },
  // {
  //   label: "Close trade volume",
  //   group: "dailyEventsClose",
  //   groupLabel: "Daily Events - Close",
  //   id: "close_trade_volume",
  //   scale: 1,
  // },
  // {
  //   label: "Trade price of the most recent trade before market close",
  //   group: "dailyEventsClose",
  //   groupLabel: "Daily Events - Close",
  //   id: "trade_price_of_the_most_recent_trade_before_market_close",
  //   scale: 1,
  // },
  // {
  //   label: "Trade time of the most recent trade before market close",
  //   group: "dailyEventsClose",
  //   groupLabel: "Daily Events - Close",
  //   id: "trade_time_of_the_most_recent_trade_before_market_close",
  //   scale: 1,
  // },
  // {
  //   label: "Last best offer price before market close",
  //   group: "dailyEventsClose",
  //   groupLabel: "Daily Events - Close",
  //   id: "last_best_offer_price_before_market_close",
  //   scale: 1,
  // },
  // {
  //   label: "Last best bid price before market close",
  //   group: "dailyEventsClose",
  //   groupLabel: "Daily Events - Close",
  //   id: "Last best bid price before market close",
  //   scale: 1,
  // },

  ////////////////////////
  // Locally computed
  /////////////////////////

  // {
  //   group: "local",
  //   label: "Vol10",
  //   id: "Vol10",
  //   scale: 1.0,
  // },
  /*
  {
    label: "EMA30",
    id: 1,
  },
  {
    label: "EMA10",
    id: 2,
  },
  {
    label: "Vol50",
    id: 2,
  },
  */
];

analyticsIndicatorList.sort(
  (a, b) => a.group.localeCompare(b.group) || a.label.localeCompare(b.label)
);

export { analyticsIndicatorList };
