import { CANADIAN_DATA_CHANNELS_BY_FEED } from "@berkindale/berkindale-provider-quotemedia-domain";

// This is one part of the code that replaces all TSX- and TSXV-related feeds with just two feednames - TSX and TSXV
// see also getFeednames() below and downloadsSlice.js/fetchDownloadsSearchResults

const DOWNLOADS_S3_DATASET_QUOTES = import.meta.env.VITE_DOWNLOADS_S3_DATASET_QUOTES;
const DOWNLOADS_S3_DATASET_QUOTES_1MINUTE = import.meta.env.VITE_DOWNLOADS_S3_DATASET_QUOTES_1MINUTE;
const DOWNLOADS_S3_DATASET_TRADES = import.meta.env.VITE_DOWNLOADS_S3_DATASET_TRADES;
const DOWNLOADS_S3_DATASET_TRADES_1MINUTE = import.meta.env.VITE_DOWNLOADS_S3_DATASET_TRADES_1MINUTE;

const TSXFeedNames = [
  "TSX-L1",
  "TSX-Q",
  "TSXV-L1",
  "TSXV-Q",
  "TSX",
  "TSXV",
  "TSX-CBBO",
  "TSXV-CBBO",
  "TSX-CLS",
  "TSXV-CLS",
];

export const datasetList = [
  {
    dropdownName: "Quotes",
    s3Name: DOWNLOADS_S3_DATASET_QUOTES,
  },
  {
    dropdownName: "Quotes - 1 Minute",
    s3Name: DOWNLOADS_S3_DATASET_QUOTES_1MINUTE,
  },
  {
    dropdownName: "Trades",
    s3Name: DOWNLOADS_S3_DATASET_TRADES,
  },
  {
    dropdownName: "Trades - 1 Minute",
    s3Name: DOWNLOADS_S3_DATASET_TRADES_1MINUTE,
  },
  // {
  //   dropdownName: "Depth",
  //   s3Name: "",
  // }
];

export function getFeednames(): string[] {
  // replacing all TSX- and TSXV-related feeds with just two feednames - TSX and TSXV.
  const feednameList = ["TSX", "TSXV"];
  for (const channel of Object.values(CANADIAN_DATA_CHANNELS_BY_FEED)) {
    // if the channel name isn't a TSX or TSXV-related name and the name isn't already on our results list...
    if (
      !TSXFeedNames.includes(channel.name) &&
      !feednameList.includes(channel.name)
    ) {
      feednameList.push(channel.name);
    }
  }
  feednameList.sort();
  return feednameList;
}

export function getFeed(feedname: string, dataset: string): string {
  let newFeedname: string;
  if (feedname === "TSX") {
    if (
      dataset === DOWNLOADS_S3_DATASET_QUOTES_1MINUTE ||
      dataset === DOWNLOADS_S3_DATASET_QUOTES
    ) {
      newFeedname = "TSX-CBBO";
    } else if (
      dataset === DOWNLOADS_S3_DATASET_TRADES_1MINUTE ||
      dataset === DOWNLOADS_S3_DATASET_TRADES
    ) {
      newFeedname = "TSX-CLS";
    }
  } else if (feedname === "TSXV") {
    if (
      dataset === DOWNLOADS_S3_DATASET_QUOTES_1MINUTE ||
      dataset === DOWNLOADS_S3_DATASET_QUOTES
    ) {
      newFeedname = "TSXV-CBBO";
    } else if (
      dataset === DOWNLOADS_S3_DATASET_TRADES_1MINUTE ||
      dataset === DOWNLOADS_S3_DATASET_TRADES
    ) {
      newFeedname = "TSXV-CLS";
    }
  } else {
    newFeedname = feedname;
  }

  const singleChannel = Object.values(CANADIAN_DATA_CHANNELS_BY_FEED).find(
    (object) => object.name === newFeedname
  );

  return singleChannel?.feed || "";
}

export function getDatasetLongNames(): string[] {
  return datasetList.map((dataset) => dataset.dropdownName);
}

export function getDatasetS3Name(dropdownName: string): string {
  const newDataset = datasetList.find((d) => d.dropdownName === dropdownName);
  return newDataset?.s3Name || "";
}

export function getDatasetLongName(s3Name: string): string {
  const newDataset = datasetList.find((d) => d.s3Name === s3Name);
  return newDataset?.dropdownName || "";
}
