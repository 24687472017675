import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
import {
  qmFetchDownloadSearch,
  qmFetchDownloads,
  QMDownloadSearchInput,
} from "@berkindale/berkindale-provider-quotemedia-javascript-api";
import { getFeed } from "./downloadsHelperFunctions";
import { RootState } from "../../app/store";
import { getPreviousDay } from "../common/utils";

const maxDate = getPreviousDay(new Date(), true, true);

export interface DownloadsFilterState {
  dataset: string;
  feed: string;
  date: number;
}

export interface DownloadsState {
  downloadsSearch: DownloadFile[]; // todo find type
  loading: boolean;
  error: string | null;
  filter: DownloadsFilterState;
}

const initialState: DownloadsState = {
  downloadsSearch: [],
  loading: false,
  error: null,
  filter: {
    dataset: "",
    feed: "",
    date: maxDate.getTime(),
  },
};

export interface FetchDownloadsSearchInput {
  dataset: string;
  feed: string;
  date: number;
  jwtToken: string;
}
export interface DownloadFile {
  ETag: string;
  Key: string;
  LastModified: Date;
  Owner: { Id: string };
  Size: number;
  StorageClass: string;
}
export type FetchDownloadsSearchResponse = DownloadFile[];

export const fetchDownloadsSearch = createAsyncThunk<
  // Return type of the payload creator
  FetchDownloadsSearchResponse,
  // First argument to the payload creatore
  FetchDownloadsSearchInput
>(
  "downloads/fetchDownloadSearch",
  async (fetchDownloadsSearchInput, thunkAPI) => {
    try {
      const { dataset, feed, date, jwtToken } = fetchDownloadsSearchInput;
      const dateStr = format(date, "yyyy-MM-dd");
      const newFeed = getFeed(feed, dataset);
      const input: QMDownloadSearchInput = {
        dataset,
        feed: newFeed || "",
        date: dateStr,
        jwtToken,
      };
      const resp: DownloadFile[] = await qmFetchDownloadSearch(input);
      return resp;
    } catch (error) {
      console.log("fetchDownloadsSearch ThunkAPI error", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface FetchDownloadsInput {
  key: string;
  jwtToken: string;
}

export const fetchDownloads = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creatore
  FetchDownloadsInput
>("downloads/fetchDownloads", async (fetchDownloadsInput, thunkAPI) => {
  try {
    const { key, jwtToken } = fetchDownloadsInput;
    const input = {
      key,
      jwtToken,
    };
    await qmFetchDownloads(input);
  } catch (error) {
    console.log("fetchDownloads ThunkAPI error", error);
    return thunkAPI.rejectWithValue(error);
  }
});

// SLICE;
export const DownloadsSlice = createSlice({
  name: "downloads",
  initialState,
  reducers: {
    updateDownloadsFilter: (state, action) => {
      state.filter.dataset = action.payload.dataset;
      state.filter.feed = action.payload.feed;
      state.filter.date = action.payload.date;
      // state.bucket = action.payload.bucket;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDownloadsSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDownloadsSearch.fulfilled, (state, action) => {
        state.downloadsSearch = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchDownloadsSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
      });
  },
});

// ACTION CREATORS
export const { updateDownloadsFilter } = DownloadsSlice.actions;

// SELECTORS
export const selectDownloadsSearch = (state: RootState) =>
  state.downloads.downloadsSearch;
export const selectDownloadsLoading = (state: RootState) =>
  state.downloads.loading;
export const selectDownloadsError = (state: RootState) => state.downloads.error;
export const selectDownloadsFilter = (state: RootState) =>
  state.downloads.filter;

// SLICE REDUCER
export const downloadsReducer = DownloadsSlice.reducer;
