import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getPreviousDay } from "../common/utils";

// DATE RANGE
const minDate = new Date(Number(import.meta.env.VITE_SENTIMENT_MIN_DATE));
const maxDate = getPreviousDay();

export interface ReportFilters {
  symbol: string;
  startdate: number;
  enddate: number;
}

export interface ReportsState {
  filter: ReportFilters;
}

const initialState: ReportsState = {
  filter: {
    symbol: "TD",
    startdate: minDate.getTime(),
    enddate: maxDate.getTime(),
  },
};

// SLICE;
export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    updateReportsFilter: (state, action) => {
      state.filter.symbol = action.payload.symbol;
      state.filter.startdate = action.payload.startdate;
      state.filter.enddate = action.payload.enddate;
    },
  },
});

// ACTION CREATORS
export const { updateReportsFilter } = reportsSlice.actions;

// SELECTORS
export const selectReportsFilter = (state: RootState) => state.reports.filter;

// SLICE REDUCER
export const reportsReducer = reportsSlice.reducer;
