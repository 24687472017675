import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { qmFetchMatchedTrends, QMMatchedTrendsInput } from '@berkindale/berkindale-provider-quotemedia-javascript-api';
import { RootState } from "../../app/store";

interface FetchMatchedTrendsParams extends QMMatchedTrendsInput {}

export interface MatchedTrendsFilters {
    symbol: string;
    date?: string; // For single date filter
    startDate?: string; // For date range start
    endDate?: string; // For date range end
    interval?: number; // For granularity
    timestamp?: number; // Optional additional filter
    jwtToken: string;
}

export interface MatchedTrendsState {
    trends: any[];
    filters: MatchedTrendsFilters;
    loading: boolean;
    error: string | null;
}

const initialState: MatchedTrendsState = {
    trends: [],
    filters: {
        symbol: "",
        date: "",
        startDate: "",
        endDate: "",
        interval: 10, // Default interval
        timestamp: undefined,
        jwtToken:''
    },
    loading: false,
    error: null,
};



export const fetchMatchedTrends = createAsyncThunk(
    'matchedTrends/fetchMatchedTrends',
    async (params: FetchMatchedTrendsParams, thunkAPI) => {
        try {
            const results = await qmFetchMatchedTrends(params);
            return results;
        } catch (error) {
            console.error("fetchMatchedTrends ThunkAPI error", error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const matchedTrendsSlice = createSlice({
    name: 'matchedTrends',
    initialState,
    reducers: {
        updateFilters(state, action: PayloadAction<Partial<MatchedTrendsFilters>>) {
            state.filters = { ...state.filters, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMatchedTrends.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMatchedTrends.fulfilled, (state, action) => {
                state.loading = false;
                state.trends = action.payload;
            })
            .addCase(fetchMatchedTrends.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const selectMatchedTrends = (state: RootState) => state.matchedTrends.trends;
export const selectMatchedTrendsLoading = (state: RootState) => state.matchedTrends.loading;
export const selectMatchedTrendsError = (state: RootState) => state.matchedTrends.error;
export const selectMatchedTrendsFilter = (state: RootState) =>
    state.matchedTrends.filters;
export const {
    updateFilters,
  } = matchedTrendsSlice.actions;

export const matchedTrendsReducer = matchedTrendsSlice.reducer;
