import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";

import { NavBar } from "./NavBar";
import { MenuDrawer } from "./MenuDrawer";
import { useAuthenticator } from "@aws-amplify/ui-react";

const drawerWidth = 280;

export function Layout() {
  const { route } = useAuthenticator((context) => [context.route]);

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return route === "authenticated" ? (
    <Box sx={{ display: "flex" }}>
      <NavBar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        drawerWidth={drawerWidth}
        // user={user}
        // onSignOut={onSignOut}
      />
      <MenuDrawer
        open={open}
        handleDrawerClose={handleDrawerClose}
        drawerwidth={drawerWidth}
      />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  ) : (
    <Box sx={{ display: "flex" }}>
      <Outlet />
    </Box>
  );
}
